import { getDateFormat, getPaymentStatus } from '@/mixins/main'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'BackOfficeListInfoETI',
  props: {
    sailorDocument: Object
  },
  data () {
    return {
      fields: [
        { key: 'date_create',
          label: this.$i18n.t('createDate')
        },
        { key: 'date_modified',
          label: this.$i18n.t('dateModified')
        },
        { key: 'date_start',
          label: this.$i18n.t('dateEffective')
        },
        { key: 'date_end',
          label: this.$i18n.t('dateTermination')
        }
      ],
      getDateFormat,
      getPaymentStatus
    }
  },
  computed: {
    ...mapGetters(['getDirectoryObject']),
    ...mapState({
      labelName: state => (state.main.lang === 'en') ? 'name_eng' : 'name_ukr'
    })
  }
}
